<template>
  <!-- table -->
  <v-row>
    <v-col cols="12" class="mb-2">
      <v-data-table :headers="headers" :items="insertedData" :items-per-page="10" item-key="account_id"
        class="elevation-1" :footer-props="{
          showFirstLastPage: true,

        }">
        <template v-slot:item.class_school="{ item }">
          <ul>
            <li v-for="(user, index) in item.class_school" :key="index"> {{ user.class_name + ' - ' + user.leader }}
            </li>
          </ul>
        </template>
        <template v-slot:item.subject_id="{ item }">
          <ul>
            <li v-for="(user, index) in item.subjects" :key="index"> {{ user.subject_id.subject_name }} </li>
          </ul>
        </template>
        <template v-slot:item.degree_max="{ item }">
          <ul>
            <li v-for="(user, index) in item.subjects" :key="index"> {{ user.degree_max }} </li>
          </ul>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="#FF5252" v-bind="attrs" class="ml-2" size="20" v-on="on" @click="deleteItem(item)">
                fa-trash </v-icon>
            </template>
            <span>حذف</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" v-bind="attrs" size="20" v-on="on" @click="editItem(item)"> fa-edit </v-icon>
            </template>
            <span>تعديل</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    insertedData: Array,
  },

  data() {
    return {
      headers: [
        { text: 'اسم الامتحان', value: 'degree_exam_name', sortable: false },
        { text: 'الصف والشعبة', value: 'class_school', sortable: false },
        { text: 'مادة الامتحان', value: 'subject_id', sortable: false },
        { text: 'الدرجات', value: 'degree_max', sortable: false },
        { text: 'العمليات', value: 'actions', sortable: false },
      ],


      editedItem: {
        class_school: null,
        exams_name: null,
        exams_schedule: [
          {
            exams_for_teacher: null,
            schedule_exams_subject: null,
            exam_date: null,
            exam_description: null,
            menuExamDate: false,
            menuExamDateEdit: false,
          },
        ],
      },


      deletedItem: {},

      editedIndex: -1,

    }
  },


  methods: {
    editItem(item) {
      this.$emit('editItem', item)
    },

    deleteItem(item) {
      this.$emit('deleteItem', item)
    },

  }
}
</script>
