<template>
  <div class="team">
    <v-container class="indigo lighten-5 my-5">
      <v-card class="white pa-3">
        <h1 class="text-center mb-3 subtitle-4 black--text"> المواد الامتحانية </h1>
        <h3 class="text-center mb-3 subtitle-4 black--text"> {{ $route.params.name }} </h3>
        <v-row>
          <v-col md="3" sm="3" xs="12" align-self="center">
            <v-btn tile color="primary" @click="addDailog.open = true"> اضافة مادة امتحانية <v-icon right> fa-plus
              </v-icon>
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-col md="3" sm="3" xs="12">
            <v-text-field v-model="table.search" label="البحث" append-icon="mdi-magnify" single-line hide-details>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table :loading="table.loading" loading-text="جاري التحميل ... الرجاء الانتظار" :headers="headers"
              :items="studentsData" :search="table.search" :items-per-page="10" item-key="account_id"
              class="elevation-1" :footer-props="{
                showFirstLastPage: true
              }">
              <template slot="item._id" slot-scope="props"> {{ props.index + 1 }} </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" class="ml-2" v-bind="attrs" size="18" v-on="on" @click="editItem(item)">
                      fa-edit </v-icon>
                  </template>
                  <span>تعديل</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="#FF8A80" class="ml-2" v-bind="attrs" size="18" v-on="on" @click="deleteItem(item)">
                      fa-trash </v-icon>
                  </template>
                  <span>حذف</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <!-- delete dialog -->
    <v-dialog v-model="dialogDelete" max-width="500.000004px">
      <v-card>
        <v-card-title class="headline justify-center"> هل انت متأكد من حذف هذا الامتحان ؟ </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogDelete = false"> الغاء </v-btn>
          <v-btn color="primary" :loading="deleteItemLoading" @click="deleteItemConfirm"> حذف </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End delete dailog -->
    <!-- add dialog -->
    <v-dialog v-model="addDailog.open" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">اضافة مادة امتحانية</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="addDailog.isFormValid">
              <v-row>
                <!-- schedule_exams_subject -->
                <v-col md="6" cols="12">
                  <v-autocomplete v-model="exam_data.schedule_exams_subject" :loading="subjectLoading"
                    :rules="Rules.schedule_exams_subject" :items="subjectsData" item-text="subject_name" clearable
                    outlined dense label="اسم المادة" @click:clear="exam_data.schedule_exams_subject = null">
                  </v-autocomplete>
                </v-col>
                <!-- schedule_exams_for_teacher -->
                <v-col md="6" cols="12">
                  <!-- :rules="Rules.teacherSelect" -->
                  <v-autocomplete v-model="exam_data.schedule_exams_for_teacher" :rules="Rules.teacherSelect"
                    :loading="addDailog.teacherLoading" :items="teacherSelect" item-text="account_name" item-value="_id"
                    clearable outlined dense label="الاستاذ" @click:clear="exam_data.schedule_exams_for_teacher = null">
                  </v-autocomplete>
                </v-col>
                <!-- schedule_exams_date -->
                <v-col md="12" cols="12">
                  <v-menu v-model="menuExamDate" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="289.999998px">
                    <!-- :rules="Rules.schedule_exams_date" -->
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="exam_data.schedule_exams_date" :rules="Rules.exam_date" dense
                        label="تاريخ الامتحان" outlined clearable readonly v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="exam_data.schedule_exams_date" @input="menuExamDate = false">
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <!-- schedule_exam_max_degree -->
                <!-- <v-col md="6" cols="12">
                  <v-text-field v-model="exam_data.schedule_exam_max_degree" label="الدرجة القصوى للامتحان"
                    :rules="Rules.schedule_exam_max_degree" outlined dense required></v-text-field>
                </v-col> -->
                <!-- schedule_exam_description -->
                <v-col cols="12">
                  <v-textarea rows="2" v-model="exam_data.schedule_exam_description" label="وصف الامتحان" outlined dense
                    required></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="addDailog.open = false"> الغاء </v-btn>
          <v-btn color="primary" :loading="addDailog.loading" :disabled="!addDailog.isFormValid" @click="submitAdd">
            اضافة </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- add dialog -->
    <!-- edit dialog -->
    <v-dialog v-model="editDailog.open" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">تعديل مادة امتحانية</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="editDailog.isFormValid">
              <v-row>
                <!-- schedule_exams_subject -->
                <v-col md="6" cols="12">
                  <v-autocomplete v-model="editedItem.schedule_exams_subject" :loading="subjectLoading"
                    :rules="editRules.schedule_exams_subject" :items="subjectsData" item-text="subject_name" clearable
                    outlined dense label="اسم المادة" @click:clear="editedItem.schedule_exams_subject = null">
                  </v-autocomplete>
                </v-col>
                <!-- schedule_exams_for_teacher -->
                <v-col md="6" cols="12">
                  <!-- :rules="editRules.schedule_exams_for_teacher" -->
                  <v-autocomplete v-model="editedItem.schedule_exams_for_teacher" :loading="addDailog.teacherLoading"
                    :items="teacherSelect" item-text="account_name" item-value="_id" clearable outlined dense
                    label="الاستاذ" @click:clear="editedItem.schedule_exams_for_teacher = null"></v-autocomplete>
                </v-col>
                <!-- exam_date -->
                <v-col md="12" cols="12">
                  <v-menu v-model="menuEditExamDate" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="289.999998px">
                    <!-- :rules="editRules.schedule_exams_date" -->
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="editedItem.schedule_exams_date" :rules="Rules.exam_date" dense
                        label="تاريخ الامتحان" outlined clearable readonly v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="editedItem.schedule_exams_date" @input="menuEditExamDate = false">
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <!-- max_degree -->
                <!-- <v-col md="6" cols="12">
                  <v-text-field v-model="editedItem.schedule_exam_max_degree" label="الدرجة القصوى للامتحان"
                    :rules="editRules.schedule_exam_max_degree" outlined dense required></v-text-field>
                </v-col> -->
                <!-- schedule_exam_description -->
                <v-col cols="12">
                  <v-textarea rows="2" v-model="editedItem.schedule_exam_description" label="وصف للامتحان" outlined
                    dense required></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="editDailog.open = false"> الغاء </v-btn>
          <v-btn color="primary" :loading="editDailog.loading" :disabled="!editDailog.isFormValid" @click="submitEdit">
            تعديل </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- add dialog -->
    <!--- Dailog for show info to user-->
    <v-dialog v-model="dialogData.open" max-width="500.000004px">
      <v-toolbar :color="dialogData.color" dense></v-toolbar>
      <v-card>
        <v-card-title class="headline justify-center"> {{ dialogData.bodyText }} </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogData.open = false"> تم </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import api from '@/api/api'
export default {
  data() {
    return {
      menuEditExamDate: false,

      menuExamDate: false,

      deleteItemLoading: false,

      dialogDelete: false,

      addDailog: {
        open: false,
        loading: false,
        isFormValid: false,
        teacherLoading: false,
      },

      editDailog: {
        open: false,
        loading: false,
        isFormValid: false,
        teacherLoading: false,
      },

      exam_data: {
        schedule_exams_subject: null,
        schedule_exams_for_teacher: null,
        schedule_exams_date: null,
        schedule_exam_max_degree: null,
        schedule_exam_description: null,
        schedule_exams_day: null
      },

      Rules: {
        schedule_exams_subject: [value => !!value || 'الاسم مطلوب'],
        teacherSelect: [value => !!value || 'الاستاذ مطلوب'],
        exam_date: [value => !!value || 'التاريخ مطلوب'],
        // max_degree: [
        //   value => !!value || 'الدرجة القصوى مطلوبة',
        //   value => !isNaN(parseFloat(value)) || 'يجب ان تكون ارقام',
        //   value => (value >= 0 && value <= 100) || 'يجب ان تكون بين 1 و 100',
        // ],
      },

      editRules: {
        schedule_exams_subject: [value => !!value || 'الاسم مطلوب'],
        schedule_exams_for_teacher: [value => !!value || 'الاستاذ مطلوب'],
        schedule_exam_max_degree: [
          value => !!value || 'الدرجة القصوى مطلوبة',
          value => !isNaN(parseFloat(value)) || 'يجب ان تكون ارقام',
          value => (value >= 0 && value <= 100) || 'يجب ان تكون بين 1 و 100',
        ],
        schedule_exams_date: [value => !!value || 'التاريخ مطلوب'],
      },

      dialogData: {
        open: false,
        color: 'primary',
        bodyText: 'test',
      },

      deletedItem: {},

      editedItem: {},

      table: {
        loading: false,
        search: null,
        imageUrlForShow: null,
        showImageDailog: false,
      },
      headers: [
        {
          text: '#',
          align: 'start',
          width: 1,

          value: '_id',
        },
        {
          text: 'اسم المادة',
          value: 'schedule_exams_subject',
        },
        {
          text: 'تاريخ الامتحان',
          value: 'schedule_exams_date',
        },
        {
          text: 'اسم الاستاذ',
          value: 'schedule_exams_for_teacher_name',
        },
        // {
        //   text: 'درجة الامتحان القصوى',
        //   value: 'schedule_exam_max_degree',
        // },
        {
          text: 'ملاحظة الامتحان',
          value: 'schedule_exam_description',
        },
        { text: 'العمليات', value: 'actions', sortable: false },
      ],
      studentsData: [],
      teacherSelect: [],
      subjectsData: [],
      subjectLoading: false
    }
  },
  mounted() {
    this.getStudentData()
    this.getTeachers()
    this.getSubject()
  },

  methods: {
    async getStudentData() {
      this.table.loading = true

      const response = await api.getExamsSchedule(this.$route.params.id)
      if (response.status === 401) {
        this.table.loading = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.table.loading = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.table.loading = false
        this.studentsData = response.data.results.exams_schedule
      }
    },

    deleteItem(item) {
      this.deletedItem = { ...item }
      this.dialogDelete = true
    },

    async deleteItemConfirm() {
      this.deleteItemLoading = true

      const response = await api.removeExamsSchedule(this.$route.params.id, this.deletedItem._id)
      if (response.status === 401) {
        this.dialogDelete = false
        this.deleteItemLoading = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.dialogDelete = false
        this.deleteItemLoading = false
        this.showDialogfunction(response.data.results, '#FF8A80')
      } else {
        this.dialogDelete = false
        this.deleteItemLoading = false
        this.getStudentData()
        this.showDialogfunction(response.data.results, 'primary')
      }
    },

    editItem(item) {
      this.editedItem = { ...item }
      this.editDailog.open = true
    },

    async submitEdit() {
      this.editDailog.loading = true

      this.editedItem.schedule_exams_day = this.getDayName(this.editedItem.schedule_exams_date)

      const response = await api.editExamsSchedule(this.$route.params.id, this.editedItem)
      if (response.status === 401) {
        this.editDailog.loading = false
        this.editDailog.open = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.editDailog.loading = false
        this.editDailog.open = false
        this.showDialogfunction(response.data.results, '#FF8A80')
      } else {
        this.editDailog.loading = false
        this.editDailog.open = false
        this.getStudentData()
        this.showDialogfunction(response.data.results, 'primary')
      }
    },

    async submitAdd() {
      this.addDailog.loading = true

      this.exam_data.schedule_exams_day = this.getDayName(this.exam_data.schedule_exams_date)

      const response = await api.addExamsSchedule(this.$route.params.id, this.exam_data)
      if (response.status === 401) {
        this.addDailog.loading = false
        this.addDailog.open = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.addDailog.loading = false
        this.addDailog.open = false
        this.showDialogfunction(response.data.results, '#FF8A80')
      } else {
        this.addDailog.loading = false
        this.addDailog.open = false
        this.getStudentData()
        this.showDialogfunction(response.data.results, 'primary')
      }
    },

    async getTeachers() {
      this.addDailog.teacherLoading = true


      const response = await api.getAllTeachers()
      if (response.status === 401) {
        this.addDailog.teacherLoading = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.addDailog.teacherLoading = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.addDailog.teacherLoading = false
        this.teacherSelect = response.data.results
      }
    },

    async getSubject() {
      this.subjectLoading = true

      const response = await api.getSubject()

      if (response.status === 401) {
        this.subjectLoading = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.subjectLoading = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.subjectLoading = false
        this.subjectsData = response.data.results
      }

    },

    getDayName(date) {
      const dayName = new Date(date).toLocaleString('ar-iq', {
        weekday: 'long',
      })

      return dayName
    },

    showDialogfunction(bodyText, color) {
      this.dialogData.open = true
      this.dialogData.bodyText = bodyText
      this.dialogData.color = color
    },

    goToStudentDegreePage(id, name) {
      this.$router.push(`/exams/studentDegree/${this.$route.params.class_school}/${id}/${name}`)
    },
  },
}
</script>

<style>
.teacher_image_table {
  cursor: pointer;
}
</style>

<style scoped>
a {
  text-decoration: none;
}
</style>
