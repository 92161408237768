var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.insertedData,"items-per-page":10,"item-key":"account_id","footer-props":{
        showFirstLastPage: true,

      }},scopedSlots:_vm._u([{key:"item.class_school",fn:function(ref){
      var item = ref.item;
return [_c('ul',_vm._l((item.class_school),function(user,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(user.class_name + ' - ' + user.leader)+" ")])}),0)]}},{key:"item.subject_id",fn:function(ref){
      var item = ref.item;
return [_c('ul',_vm._l((item.subjects),function(user,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(user.subject_id.subject_name)+" ")])}),0)]}},{key:"item.degree_max",fn:function(ref){
      var item = ref.item;
return [_c('ul',_vm._l((item.subjects),function(user,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(user.degree_max)+" ")])}),0)]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"#FF5252","size":"20"},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" fa-trash ")])]}}],null,true)},[_c('span',[_vm._v("حذف")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","size":"20"},on:{"click":function($event){return _vm.editItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" fa-edit ")])]}}],null,true)},[_c('span',[_vm._v("تعديل")])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }